import React, { useEffect, useState } from 'react';
import authService from '../Services/auth.service';
import Swal from 'sweetalert2';

const AllBlogs = () => {
    const [blogsData, setBlogsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [boltotalPages, setTotalPages] = useState(1);


    useEffect(() => {
        const limit = 10; // Adjust the limit according to your API's pagination settings
        loadBlogs(currentPage, limit);
    }, [currentPage]);

    const loadBlogs = (page, limit) => {
        authService.getBlog(page, limit).then((response) => {
            // console.log(response.data.data.docs);
            setBlogsData(response.data.data.docs);
            setTotalPages(response.data.data.totalPages);
        }).catch(error => {
            console.log(error);
        });
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < boltotalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const titleMaxLength = 50; // Set the maximum length for the title
    const descriptionMaxLength = 150; //

    const handleDelete = (id) => {
        var payload = { "_id": id }
        authService.softDeletBlog(payload).then((response) => {
            if (response.data.statusCode == 200) {
                Swal.fire({
                    title: "Success",
                    text: "Blog Status Updated Successfully.",
                    icon: "success",
                    timer: 1000, // Automatically close after 1 second (1000 milliseconds)
                    showConfirmButton: false, // Hide the "OK" button
                });
                 window.location.reload();

            }
        }).catch(error => {
            console.log(error);
        });
    }
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row mb-5">
                {blogsData.map((items, index) => (
                    <div className="col-md-6 col-lg-4 mb-3" key={index}>
                        <div className="card h-100 position-relative">
                            <img className="card-img-top" src={items.featureImg} alt={items.featureImg} />
                            <div className="card-body">
                                <h5 className="card-title">{truncateText(items.title, titleMaxLength)}</h5>
                                <p className="card-text">{truncateText(items.shortDescription, descriptionMaxLength)}</p>
                            </div>
                            <button type='button' className="btn page-link delete-icon" onClick={() => handleDelete(items._id)}>
                                <i className="bx bx-trash"></i>
                            </button>
                        </div>
                    </div>

                ))}
            </div>
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handlePrevPage}>Previous</button>
                    </li>
                    {Array.from({ length: boltotalPages }, (_, index) => (
                        <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                            <button className="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === boltotalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handleNextPage}>Next</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default AllBlogs;
