import React, { useEffect, useState } from 'react';
import authService from '../Services/auth.service';

const AllContactPage = () => {
    const [contactData, setContactData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [boltotalPages, setTotalPages] = useState(1);


    useEffect(() => {
        const limit = 10; // Adjust the limit according to your API's pagination settings
        loadContacts(currentPage, limit);
    }, [currentPage]);

    const loadContacts = (page, limit) => {
        authService.getContacts(page, limit).then((response) => {
            console.log(response, "-------------");
            setContactData(response.data.data.docs);
            setTotalPages(response.data.data.totalPages);
        }).catch(error => {
            console.log(error);
        });
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < boltotalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const titleMaxLength = 50; // Set the maximum length for the title
    const descriptionMaxLength = 150; // 
    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <div class="card">
                <h5 class="card-header">All Contact List</h5>
                <div class="table-responsive text-nowrap">
                    <table class="table">
                        <thead class="table-dark">
                            <tr>
                                <th className='text-white'>Sr No</th>
                                <th className='text-white'>Name</th>
                                <th className='text-white'>Email</th>
                            </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                            {contactData.map((item, index) => {
                                return <>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.fullName}</td>
                                        <td>{item.email}</td>
                                    </tr>
                                </>
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <br />
            <br />
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handlePrevPage}>Previous</button>
                    </li>
                    {Array.from({ length: boltotalPages }, (_, index) => (
                        <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                            <button className="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === boltotalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handleNextPage}>Next</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default AllContactPage;
