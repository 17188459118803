import React, { useEffect, useState } from 'react'
import authService from '../../Services/auth.service';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const REGEMAIL = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;

const Registration = () => {
    const navigate = useNavigate();

    const [isSubmit, setIsSubmit] = useState(false);

    const [formData, setFormData] = useState({
        fullName: "",
        username: '',
        email: '',
        password: '',
    });

    const [errorFormData, seterrorFormData] = useState({
        fullName: "",
        username: '',
        email: '',
        password: '',
    });

    const handleInputFields = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value });

        switch (name) {
            case 'fullName':
                errorFormData.fullName = value.length > 0 ? "" : "Enter fullName";
                break;
            case 'username':
                errorFormData.username = value.length > 0 ? "" : "Enter username";
                break;
            case 'email':
                errorFormData.email = REGEMAIL.test(value) ? "" : "Enter Valid email";
                break;
            case 'password':
                errorFormData.password = PASSWORD_REGEX.test(value) ? "" : "Enter Valid Password";
                break;
        }
        seterrorFormData(errorFormData)
    };

    useEffect(() => {
        if (Object.keys(errorFormData).length === 0 && isSubmit) {
        }
    }, []);

    const validate = () => {
        if (!formData.fullName) {
            errorFormData.fullName = "Enter fullName";
        }
        if (!formData.username) {
            errorFormData.username = "Enter username";
        }
        if (!formData.email) {
            errorFormData.email = "Enter email";
        }
        if (!formData.password) {
            errorFormData.password = "Enter password";
        }
        return errorFormData;
    };

    const submitForm = (e) => {
        e.preventDefault();
        seterrorFormData(validate(formData));
        setIsSubmit(true);

        const Payload = {
            fullName: formData.fullName,
            email: formData.email,
            username: formData.username,
            password: formData.password
        }
        if (errorFormData.fullName == "" && errorFormData.email == "" && errorFormData.username == "" && errorFormData.password == "") {
            console.log(errorFormData)
            authService.regsiterUser(Payload).then((response) => {
                if (response.data.statusCode == 200) {
                    Swal.fire({
                        title: "Success",
                        text: "User is Regsiter succesfully!!.",
                        icon: "success",
                        timer: 2000, // Automatically close after 1 second (1000 milliseconds)
                        showConfirmButton: false, // Hide the "OK" button
                    });
                }
            }).catch((error) => {
                console.log(error.response.status==409)
                console.log(error.request.responseText)
                if(error.response.status==409){
                    Swal.fire({
                        title: "Opps!!",
                        text: "User with email or username already exists.",
                        icon: "error",
                        timer: 2000, // Automatically close after 1 second (1000 milliseconds)
                        showConfirmButton: false, // Hide the "OK" button
                    });
                }
            });

        }
    }

    const handleSignin = (e) => {
        e.preventDefault();
        navigate("/")
    }
    return (
        <>
            <div class="container-xxl col-sm-4 col-12">
                <div class="authentication-wrapper authentication-basic container-p-y">
                    <div class="authentication-inner">
                        <div class="card">
                            <div class="card-body">
                                <div class="app-brand justify-content-center">
                                    <a href="#" class="app-brand-link gap-2">
                                        {/* <span class="app-brand-logo demo">
                                            <svg width="25" viewBox="0 0 25 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                                                <defs>
                                                    <path d="M13.7918663,0.358365126 L3.39788168,7.44174259 C0.566865006,9.69408886 -0.379795268,12.4788597 0.557900856,15.7960551 C0.68998853,16.2305145 1.09562888,17.7872135 3.12357076,19.2293357 C3.8146334,19.7207684 5.32369333,20.3834223 7.65075054,21.2172976 L7.59773219,21.2525164 L2.63468769,24.5493413 C0.445452254,26.3002124 0.0884951797,28.5083815 1.56381646,31.1738486 C2.83770406,32.8170431 5.20850219,33.2640127 7.09180128,32.5391577 C8.347334,32.0559211 11.4559176,30.0011079 16.4175519,26.3747182 C18.0338572,24.4997857 18.6973423,22.4544883 18.4080071,20.2388261 C17.963753,17.5346866 16.1776345,15.5799961 13.0496516,14.3747546 L10.9194936,13.4715819 L18.6192054,7.984237 L13.7918663,0.358365126 Z"
                                                        id="path-1"></path>
                                                    <path
                                                        d="M5.47320593,6.00457225 C4.05321814,8.216144 4.36334763,10.0722806 6.40359441,11.5729822 C8.61520715,12.571656 10.0999176,13.2171421 10.8577257,13.5094407 L15.5088241,14.433041 L18.6192054,7.984237 C15.5364148,3.11535317 13.9273018,0.573395879 13.7918663,0.358365126 C13.5790555,0.511491653 10.8061687,2.3935607 5.47320593,6.00457225 Z"
                                                        id="path-3"></path>
                                                    <path
                                                        d="M7.50063644,21.2294429 L12.3234468,23.3159332 C14.1688022,24.7579751 14.397098,26.4880487 13.008334,28.506154 C11.6195701,30.5242593 10.3099883,31.790241 9.07958868,32.3040991 C5.78142938,33.4346997 4.13234973,34 4.13234973,34 C4.13234973,34 2.75489982,33.0538207 2.37032616e-14,31.1614621 C-0.55822714,27.8186216 -0.55822714,26.0572515 -4.05231404e-15,25.8773518 C0.83734071,25.6075023 2.77988457,22.8248993 3.3049379,22.52991 C3.65497346,22.3332504 5.05353963,21.8997614 7.50063644,21.2294429 Z"
                                                        id="path-4" ></path>
                                                    <path
                                                        d="M20.6,7.13333333 L25.6,13.8 C26.2627417,14.6836556 26.0836556,15.9372583 25.2,16.6 C24.8538077,16.8596443 24.4327404,17 24,17 L14,17 C12.8954305,17 12,16.1045695 12,15 C12,14.5672596 12.1403557,14.1461923 12.4,13.8 L17.4,7.13333333 C18.0627417,6.24967773 19.3163444,6.07059163 20.2,6.73333333 C20.3516113,6.84704183 20.4862915,6.981722 20.6,7.13333333 Z"
                                                        id="path-5"
                                                    ></path>
                                                </defs>
                                                <g id="g-app-brand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="Brand-Logo" transform="translate(-27.000000, -15.000000)">
                                                        <g id="Icon" transform="translate(27.000000, 15.000000)">
                                                            <g id="Mask" transform="translate(0.000000, 8.000000)">
                                                                <mask id="mask-2" fill="white">
                                                                    <use xlink:href="#path-1"></use>
                                                                </mask>
                                                                <use fill="#696cff" xlink:href="#path-1"></use>
                                                                <g id="Path-3" mask="url(#mask-2)">
                                                                    <use fill="#696cff" xlink:href="#path-3"></use>
                                                                    <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-3"></use>
                                                                </g>
                                                                <g id="Path-4" mask="url(#mask-2)">
                                                                    <use fill="#696cff" xlink:href="#path-4"></use>
                                                                    <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-4"></use>
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Triangle"
                                                                transform="translate(19.000000, 11.000000) rotate(-300.000000) translate(-19.000000, -11.000000) "
                                                            >
                                                                <use fill="#696cff" xlink:href="#path-5"></use>
                                                                <use fill-opacity="0.2" fill="#FFFFFF" xlink:href="#path-5"></use>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span> */}
                                        <span class="app-brand-text demo text-body fw-bolder text-capitalize">Saletancy</span>
                                    </a>
                                </div>
                                <h4 class="mb-2 mt-4">Registration! 🚀</h4>
                                <p class="mb-4">Make your app management easy and fun!</p>

                                <form id="formAuthentication" class="mb-3" onSubmit={submitForm}>
                                    <div class="mb-3">
                                        <label for="fullName" class="form-label">Full Name</label>
                                        <input type="text" class="form-control" id="fullName" name="fullName" placeholder="Enter your full Name" onChange={handleInputFields} />
                                        <span className='text-danger'>{errorFormData.fullName}</span>
                                    </div>
                                    <div class="mb-3">
                                        <label for="username" class="form-label">Username</label>
                                        <input type="text" class="form-control" id="username" name="username" placeholder="Enter your username" onChange={handleInputFields} />
                                        <span className='text-danger'>{errorFormData.username}</span>
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label">Email</label>
                                        <input type="text" class="form-control" id="email" name="email" placeholder="Enter your email" onChange={handleInputFields} />
                                        <span className='text-danger'>{errorFormData.email}</span>

                                    </div>
                                    <div class="mb-3 form-password-toggle">
                                        <label class="form-label" for="password">Password</label>
                                        <div class="input-group input-group-merge">
                                            <input type="password" id="password" class="form-control" name="password"
                                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                aria-describedby="password" onChange={handleInputFields}
                                            />
                                            <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                                        </div>
                                        <span className='text-danger'>{errorFormData.password}</span>

                                    </div>


                                    <button class="btn btn-primary d-grid w-100">Sign up</button>
                                </form>

                                <p class="text-center">
                                    <span>Already have an account?</span>
                                    <a href="#" onClick={handleSignin}>
                                        <span> Sign in instead</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Registration