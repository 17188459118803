import React, { useEffect, useState } from 'react';
import authService from '../Services/auth.service';
import Swal from 'sweetalert2';
 
const AddCategory = () => {
    const [isSubmit, setIsSubmit] = useState(false);

    const [formData, setFormData] = useState({
        category: '',
    });

    const [errorFormData, setErrorFormData] = useState({
        category: '',
    });


    const handleInputsBlogs = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
        setErrorFormData({ ...errorFormData, [name]: value.length > 0 ? '' : `Enter ${name}` });

    };

    useEffect(() => {
        if (Object.keys(errorFormData).length === 0 && isSubmit) {
        }
    }, [errorFormData, isSubmit]);

    const validate = () => {
        let errors = {};

        if (!formData.category) errors.category = "Enter category";


        return errors;
    };

    const submitForm = (e) => {
        e.preventDefault();
        setErrorFormData(validate(formData));
        setIsSubmit(true);

        if (Object.keys(errorFormData).length === 0) {
            if (Object.keys(errorFormData).length === 0) {
                const payload = {
                    "name": formData.category
                }

 
                authService.addCategory(payload).then((response) => {
                    if (response.data.statusCode == 200) {
                        Swal.fire({
                            title: "Success",
                            text: "Category added succeesfully.",
                            icon: "success",
                            timer: 1000, // Automatically close after 1 second (1000 milliseconds)
                            showConfirmButton: false, // Hide the "OK" button
                        });
                        window.location.reload()

                    }
                }).catch(error => {
                    // Handle error
                });
            }
        }
    };




    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">Add Blog</h4>
            <div className="row">
                <div className="col-xl">
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Add Blog</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submitForm}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="category">category</label>
                                    <input type="text" className="form-control" placeholder="Enter category" name='category' value={formData.title} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.category}</span>
                                </div>

                                <button type="submit" className="btn btn-primary">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCategory;
