import React, { useState } from 'react';
import authService from '../../Services/auth.service';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const REGEMAIL = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errorFormData, setErrorFormData] = useState({
    email: '',
    password: '',
  });

  const [toogle, settoogle] = useState(false)

  const handleInputFields = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value });

    switch (name) {
      case 'email':
        errorFormData.email = REGEMAIL.test(value) ? "" : "Enter Valid email";
        break;
      case 'password':
        errorFormData.password = PASSWORD_REGEX.test(value) ? "" : "Enter Valid Password";
        break;
    }
    setErrorFormData(errorFormData)
  };

  const validate = () => {
    const errors = {};
    if (!formData.email) {
      errors.email = 'Enter email';
    }
    if (!formData.password) {
      errors.password = 'Enter password';
    }
    return errors;
  };

  const submitForm = (e) => {
    e.preventDefault();
    const errors = validate();
    setErrorFormData(errors);

    if (Object.keys(errors).length === 0) {
      // Proceed with login
      const loginPayload = {
        email: formData.email,
        password: formData.password,
      };

      if (errorFormData.email == "" && errorFormData.password == "") {
        authService.loginUser(loginPayload)
          .then((response) => {
            // console.log(response.data)
            localStorage.setItem("accessToken", response.data.data.accessToken);
            if (response.data.statusCode == 200) {
              Swal.fire({
                title: "Success",
                text: "Wait for a second, we will serve you the Dashboard.",
                icon: "success",
                timer: 500, // Automatically close after 1 second (1000 milliseconds)
                showConfirmButton: false, // Hide the "OK" button
              });

              setTimeout(() => {
                navigate("/home");
              }, 1000);
            }
          })
          .catch((error) => {
            console.log(error)
          });
      }
    }
  };

  const handleCreareAccount = (e) => {
    e.preventDefault();
    navigate("/registration")
  }

  const handletoggle = () => {
    settoogle(!toogle)

  }

  return (
    <>
      <div className="container-xxl col-sm-4 col-12">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center">
                  <a href="index.html" className="app-brand-link gap-2">
                    <span className="app-brand-logo demo" />
                    <span className="app-brand-text demo text-body fw-bolder">Saletancy</span>
                  </a>
                </div>
                <h4 className="mb-2 mt-4">Login! 👋</h4>
                <p className="mb-4">Please sign-in to your account and start the adventure</p>

                <form id="formAuthentication" className="mb-3" action="index.html" method="POST" onSubmit={submitForm}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      onChange={handleInputFields}
                    />
                    <span className="text-danger">{errorFormData.email}</span>
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">Password</label>

                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type={toogle !== true ? 'password' : 'text'}
                        id="password"
                        className="form-control"
                        name="password"
                        aria-describedby="password"
                        onChange={handleInputFields}
                      />
                      <span className="input-group-text cursor-pointer" onClick={handletoggle}> <i className={`bx ${toogle ? 'bx-show' : 'bx-hide'}`} /></span>
                    </div>
                    <span className="text-danger">{errorFormData.password}</span>
                  </div>

                  <div className="mb-3">
                    <button className="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                  </div>
                </form>

                {/* <p className="text-center">
                  <span>New on our platform?</span>
                  <a href='#' onClick={handleCreareAccount}>
                    <span> Create an account</span>
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
