import "./App.css";
import Home from "./Component/Home";
import { Routes, Route } from "react-router-dom";

import WithoutNav from "./Component/WithoutNav/WithoutNav";
import WithNav from "./Component/WithNav/WithNav";
import Login from "./Component/WithoutNav/Login";
import AddBlogs from './Pages/AddBlogs'
import { useEffect } from "react";
import axios from "axios";
import Registration from "./Component/WithoutNav/Registration";
import AllBlogs from "./Pages/AllBlogs";
import AllContactPage from "./Pages/AllContactPage";
import AddCategory from "./Pages/AddCategory";

function App() {  
  return (
    <>
      <Routes>
        <Route element={<WithoutNav />}>
          <Route path="/" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
        </Route>
        <Route element={<WithNav />}>
          <Route exact path="/home" element={<Home />}></Route>
          <Route exact path="/blogs" element={<AddBlogs />}></Route>
          <Route exact path="/all-blogs" element={<AllBlogs />}></Route>
          <Route exact path="/all-contacts" element={<AllContactPage />}></Route>
          <Route exact path="/add-category" element={<AddCategory />}></Route>          
        </Route>
      </Routes>
    </>
  );
}

export default App;
