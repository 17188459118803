import React, { useEffect, useState } from 'react';
import authService from '../Services/auth.service';
import Swal from 'sweetalert2';
import Select from 'react-select';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'


const AddBlogs = () => {

    const [isSubmit, setIsSubmit] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        author: '',
        categories: [],
        featureImg: '',
        salesQualifiedLeads: '',
        marketingQualifiedLead: '',
        socialMediaConnection: '',
        industry: '',
        Location: '',
        campaignType: '',
        headquaters: '',
        targetLocation: '',
        targetIndustries: '',
        targetContacts: '',
        downloadFullStory: '',
        shortDescription: ''
    });

    const [errorFormData, setErrorFormData] = useState({
        title: '',
        description: '',
        author: '',
        categories: '',
        featureImg: '',
        salesQualifiedLeads: '',
        marketingQualifiedLead: '',
        socialMediaConnection: '',
        industry: '',
        Location: '',
        campaignType: '',
        headquaters: '',
        targetLocation: '',
        targetIndustries: '',
        targetContacts: '',
        downloadFullStory: '',
        shortDescription: ''
    });
    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'align': [] }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ]
    };

    const formats = [
        'header', 'font', 'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'link', 'image', 'align'
    ];

    const handleChange = (selected) => {
        setSelectedOptions(selected);
        setFormData({ ...formData, categories: selected.map(option => option.value) });
    };


    const handleInputsBlogs = (e) => {

        const { name, value, files } = e.target;
        console.log(value, "sd")
        if (name === "featureImg" || name === "downloadFullStory") {
            const file = files[0];
            setFormData({ ...formData, [name]: file });
            setErrorFormData({ ...errorFormData, [name]: file ? '' : `Upload ${name === 'featureImg' ? 'feature image' : 'full story file'}` });
        } else {
            setFormData({ ...formData, [name]: value });
            setErrorFormData({ ...errorFormData, [name]: value.length > 0 ? '' : `Enter ${name}` });
        }
    };


    const handleSelectChange = (name, value) => {
        if (name === "description") {
            setFormData({ ...formData, [name]: value });
            setErrorFormData({ ...errorFormData, [name]: value.length > 0 ? '' : `Enter ${name}` });
        }
        console.log(formData, "formData")
        //     console.log(errorFormData, "errorFormData")
        //     console.log(value.length)
    };


    useEffect(() => {
        if (Object.keys(errorFormData).length === 0 && isSubmit) {
        }
    }, [errorFormData, isSubmit]);

    const validate = () => {
        let errors = {};

        if (!formData.title) errors.title = "Enter title";
        if (!formData.description) errors.description = "Enter description";
        if (!formData.shortDescription) errors.shortDescription = "Enter short description";
        if (!formData.author) errors.author = "Enter author";
        if (formData.categories.length === 0) errors.categories = "Select at least one category";
        if (!formData.salesQualifiedLeads) errors.salesQualifiedLeads = "Enter sales qualified leads";
        if (!formData.marketingQualifiedLead) errors.marketingQualifiedLead = "Enter marketing qualified lead";
        if (!formData.socialMediaConnection) errors.socialMediaConnection = "Enter social media connection";
        if (!formData.industry) errors.industry = "Enter industry";
        if (!formData.Location) errors.Location = "Enter location";
        if (!formData.campaignType) errors.campaignType = "Enter campaign type";
        if (!formData.headquaters) errors.headquaters = "Enter headquaters";
        if (!formData.targetLocation) errors.targetLocation = "Enter target location";
        if (!formData.targetIndustries) errors.targetIndustries = "Enter target industries";
        if (!formData.targetContacts) errors.targetContacts = "Enter target contacts";
        if (!formData.featureImg) errors.featureImg = "Upload feature image";
        if (!formData.downloadFullStory) errors.downloadFullStory = "Upload full story file";

        return errors;
    };

    const submitForm = (e) => {
        e.preventDefault();
        setErrorFormData(validate(formData));
        setIsSubmit(true);

        if (Object.keys(errorFormData).length === 0) {
            const formDataToSend = new FormData();
            if (Object.keys(errorFormData).length === 0) {
                const formDataToSend = new FormData();

                formDataToSend.append('title', formData.title);
                formDataToSend.append('description', formData.description);
                formDataToSend.append('shortDescription', formData.shortDescription);
                
                formDataToSend.append('author', formData.author);

                formDataToSend.append('featureImg', formData.featureImg);
                formDataToSend.append('salesQualifiedLeads', formData.salesQualifiedLeads);
                formDataToSend.append('marketingQualifiedLead', formData.marketingQualifiedLead);
                formDataToSend.append('socialMediaConnection', formData.socialMediaConnection);
                formDataToSend.append('downloadFullStory', formData.downloadFullStory);
                var dummyarry = []
                selectedOptions.map((item) => {
                    dummyarry.push(item.value)
                })

                console.log(dummyarry, "dummyarry")
                formDataToSend.append('categories', JSON.stringify(dummyarry));
                console.log(JSON.stringify(dummyarry), "dummyarry")




                const caseStudybasicInfoNewConat = [
                    {
                        title: "Industry",
                        shortDesc: formData.industry
                    },
                    {
                        title: "Location",
                        shortDesc: formData.Location
                    },
                    {
                        title: "Campaign Type",
                        shortDesc: formData.campaignType
                    },
                    {
                        title: "Headquaters",
                        shortDesc: formData.headquaters
                    },
                    {
                        title: "Target Location",
                        shortDesc: formData.targetLocation
                    },
                    {
                        title: "Target Industries",
                        shortDesc: formData.targetIndustries
                    },
                    {
                        title: "Target Contacts",
                        shortDesc: formData.targetContacts
                    },
                ]
                formDataToSend.append('caseStudybasicInfo', JSON.stringify(caseStudybasicInfoNewConat));
                formDataToSend.append('blogStatus', true);


                // Submit form data
                authService.addBlog(formDataToSend).then((response) => {
                    if (response.data.statusCode == 200) {
                        Swal.fire({
                            title: "Success",
                            text: "Blog Data added succeesfully.",
                            icon: "success",
                            timer: 1000, // Automatically close after 1 second (1000 milliseconds)
                            showConfirmButton: false, // Hide the "OK" button
                        });
                        window.location.reload();
                    }
                }).catch(error => {
                    // Handle error
                });
            }


        }

    };

    useEffect(() => {
        authService.getCategories().then(response => {
            if (response.data.statusCode === 200) {
                setCategories(response.data.data);
            }
        }).catch(error => {
            console.error("Error fetching categories:", error);
        });
    }, []);

    const options = categories.map(item => ({ value: item._id, label: item.name }));

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4">Add Blog</h4>
            <div className="row">
                <div className="col-xl">
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Add Blog</h5>
                        </div>
                        <div className="card-body">
                            {/* <form onSubmit={submitForm}> */}
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">Title</label>
                                    <input type="text" className="form-control" placeholder="Title" name='title' value={formData.title} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.title}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="author">Short Description</label>
                                    <textarea id="shortDescription" className="form-control" name='shortDescription' rows={"2"} placeholder="Enter description" value={formData.shortDescription} onChange={handleInputsBlogs}></textarea>
                                    <span className='text-danger'>{errorFormData.shortDescription}</span>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label" htmlFor="description">Description</label>
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        name="description"
                                        placeholder="Tell us about your ideal candidate! We'll find a match based on your description."
                                        value={formData.description}
                                        onChange={(content) => handleSelectChange("description", content)}
                                    />
                                    <span className='text-danger'>{errorFormData.description}</span>
                                </div>


                                <div className="mb-3">
                                    <label className="form-label" htmlFor="author">Author</label>
                                    <input type="text" className="form-control" name='author' placeholder="john.doe" value={formData.author} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.author}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="categories">Categories</label>
                                    <Select
                                        name="categories"
                                        isMulti
                                        value={selectedOptions}
                                        onChange={handleChange}
                                        options={options}
                                        closeMenuOnSelect={false}
                                    />
                                    <span className='text-danger'>{errorFormData.categories}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="featureImg">Feature Image</label>
                                    <input type="file" className="form-control" name="featureImg" onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.featureImg}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="downloadFullStory">Add Full Story File</label>
                                    <input type="file" className="form-control" name="downloadFullStory" onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.downloadFullStory}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="salesQualifiedLeads">Sales Qualified Leads</label>
                                    <input type="number" className="form-control" placeholder="Enter here" name='salesQualifiedLeads' value={formData.salesQualifiedLeads} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.salesQualifiedLeads}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="marketingQualifiedLead">Marketing Qualified Lead</label>
                                    <input type="number" className="form-control" placeholder="Enter here" name='marketingQualifiedLead' value={formData.marketingQualifiedLead} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.marketingQualifiedLead}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="socialMediaConnection">Social Media Connection</label>
                                    <input type="number" className="form-control" placeholder="Enter here" name='socialMediaConnection' value={formData.socialMediaConnection} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.socialMediaConnection}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="industry">Industry</label>
                                    <input type="text" className="form-control" placeholder="Title" name='industry' value={formData.industry} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.industry}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="Location">Location</label>
                                    <input type="text" className="form-control" placeholder="Location" name='Location' value={formData.Location} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.Location}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="campaignType">Campaign Type</label>
                                    <input type="text" className="form-control" placeholder="Campaign Type" name='campaignType' value={formData.campaignType} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.campaignType}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="headquaters">Headquaters</label>
                                    <input type="text" className="form-control" placeholder="Headquaters" name='headquaters' value={formData.headquaters} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.headquaters}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="targetLocation">Target Location</label>
                                    <input type="text" className="form-control" name="targetLocation" placeholder='Target Location' value={formData.targetLocation} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.targetLocation}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="targetIndustries">Target Industries</label>
                                    <input type="text" className="form-control" name="targetIndustries" placeholder='Target Industries' value={formData.targetIndustries} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.targetIndustries}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="targetContacts">Target Contacts</label>
                                    <input type="text" className="form-control" name="targetContacts" placeholder='Target Contacts' value={formData.targetContacts} onChange={handleInputsBlogs} />
                                    <span className='text-danger'>{errorFormData.targetContacts}</span>
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={submitForm}>Send</button>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBlogs;
