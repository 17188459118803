import axios from "axios";
import authHeader from "./auth.header";


// const API_URL = "http://localhost:8000/api/v1/";
const API_URL = "https://35.179.16.91/api/v1/";
 
const regsiterUser = (data) => {
  return axios.post(API_URL + "users/register", data,)
}
const loginUser = (data) => {
  return axios.post(API_URL + "users/login", data,)
}
const addBlog = (data) => {
  return axios.post(API_URL + "blog/addblog", data, { headers: authHeader() });
}
const getBlog = (page, limit) => {
  return axios.get(API_URL + `blog/getblogs?page=${page}&limit=${limit}`);
};

const getContacts = (page, limit,data) => {
  return axios.post(API_URL + `contact/getContacts?page=${page}&limit=${limit}`,data, { headers: authHeader() });
};

const addCategory = (data) => {
  return axios.post(API_URL + `category/addcategory`,data, { headers: authHeader() });
};

const getCategories = (data) => {
  return axios.get(API_URL + `category/getcategories`,data, { headers: authHeader() });
};

const softDeletBlog = (data) => {
  return axios.post(API_URL + `blog/updateblogstatus`,data, { headers: authHeader() });
};
const logOut = (data) => {
  return axios.post(API_URL + "users/logout", data, { headers: authHeader() });
}

export default {
  regsiterUser,
  loginUser,
  getBlog,
  getContacts,
  addCategory,
  getCategories,
  addBlog,
  softDeletBlog,
  logOut
}