export default function authHeader() {
 
   const token = localStorage.getItem("accessToken");
  if (token) {
    console.log(token,"tokentoken")
     return { Authorization: token };
    // return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}
